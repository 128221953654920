import Vue from "vue";
import Vuex from "vuex";
import uniqueId from "./util/uniqueId";
import { API_BASE } from "./util/constants";
import {redirectToLogin} from "./oauth"
import router from "./router";

Vue.use(Vuex);

const PERIODIC_CHECK_INTERVAL = 10000;

// assume JWT is invalid slightly before it actually expires so that users
// aren't likely to be interrupted half way through an operation
const MIN_JWT_VALIDITY = 90000;

// If the token has *actually* expired, then we'll need to redirect to a full login
// So give ourselves a two-second - if there's less than that until expiry, just send the user
// straight to the login screen
const OAUTH_REDIRECT_TIME_BUFFER = 2000;

let store = new Vuex.Store({
  state: {
    auth: {
      token: null,
      userData: null,
      expiresAt: null,
    },
    info: {
      oauthUrl: null,
      environment: null,
    },
    loader: [],
    crumbs: [],
    messages: [],
    timer: null,
  },
  mutations: {
    SET_AUTH_DATA: (state, { token, userData, expiresAt }) => {
      state.auth.token = token;
      state.auth.userData = userData;
      state.auth.expiresAt = expiresAt;
    },
    SET_INFO_DATA: (state, { oauthUrl, environment }) => {
      state.info.oauthUrl = oauthUrl;
      state.info.environment = environment;
    },
    BEGIN_LOAD: (state, id) => {
      state.loader.push(id);
    },
    END_LOAD: (state, id) => {
      const pos = state.loader.indexOf(id);
      if (pos !== -1) {
        state.loader.splice(pos, 1);
      }
    },
    SET_BREADCRUMBS: (state, crumbs) => {
      state.crumbs.splice(0, state.crumbs.length, ...crumbs);
    },
    ADD_MESSAGE: (state, message) => {
      state.messages.push(message);
    },
    REMOVE_MESSAGE: (state, id) => {
      const index = state.messages.findIndex((message) => (message.id === id));
      if (index !== -1) {
        state.messages.splice(index, 1);
      }
    },
    CLEAR_MESSAGES: (state) => {
      state.messages = [];
    },
  },
  actions: {
    async initPage({ dispatch }) {
      const token = localStorage.getItem("token");
      const expiresAt = localStorage.getItem("expiresAt");
      if ((Date.now() + MIN_JWT_VALIDITY) < expiresAt) {
        await dispatch('loadUserData', { token, expiresIn: null, expiresAt });
      }
      if (!this.state.info.environment) {
        await dispatch('loadInfo');
      }
      if (!this.state.timer) {
        this.state.timer = setInterval(() => dispatch('periodicChecks'), PERIODIC_CHECK_INTERVAL);
      }
    },
    async loadUserData({ commit }, { token, expiresIn, expiresAt }) {
      if (!expiresAt) {
        expiresAt = Date.now() + (expiresIn * 1000);
      }
      localStorage.setItem("token", token);
      localStorage.setItem("expiresAt", expiresAt + "");
      const response = await fetch(API_BASE + '/user/info', {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      const userData = await response.json();
      commit("SET_AUTH_DATA", { token: token, userData, expiresAt });
    },
    async loadInfo({ commit }) {
      const response = await fetch(API_BASE.replace("/api/v2", "") + '/actuator/info', {});
      const userData = await response.json();
      commit("SET_INFO_DATA", { oauthUrl: userData.oauthurl, environment: userData.environment });
    },
    clearUserToken({ commit }) {
      localStorage.removeItem("token");
      localStorage.removeItem("expiresAt");
      commit("SET_AUTH_DATA", { token: null, userData: null, expiresAt: null });
    },
    periodicChecks({ state }) {
      let expiresAt = state.auth.expiresAt;
      if (expiresAt) {
        // check if login token has expired to save users from getting stuck
        if (Date.now() + OAUTH_REDIRECT_TIME_BUFFER > expiresAt) {
          redirectToLogin({originalRoute: router.currentRoute.fullPath})
        }
        else if (Date.now() + MIN_JWT_VALIDITY > expiresAt) {
          redirectToLogin({silent: true, originalRoute: router.currentRoute.fullPath})
        }
      }
    },
    beginLoad({ commit }, id) {
      commit('BEGIN_LOAD', id);
    },
    endLoad({ commit }, id) {
      commit('END_LOAD', id);
    },
    setBreadcrumbs({ commit }, crumbs) {
      commit('SET_BREADCRUMBS', crumbs);
    },
    addMessage({ dispatch, commit }, message) {
      const id = uniqueId();
      commit('ADD_MESSAGE', { ...message, id });
      setTimeout(() => dispatch('removeMessage', id), message.expiryTime);
      return id;
    },
    removeMessage({ commit }, id) {
      commit('REMOVE_MESSAGE', id);
    },
  },
});

export default () => store;
