<script>
import { mapState } from "vuex";

const AUTH = {
  USER: 0,
  ADMIN: 1,
  REDS: 2,
  PERSONAL_STORAGE: 3,
  NONE: 3,
  MULTI_FILE_DOWNLOAD: 4
};

const ITEMS = [
  { label: 'repositories',     url: '/',                 auth: AUTH.USER, icon: "folder_special" },
  { label: 'nav.all',          url: '/all',              auth: AUTH.ADMIN, icon: "folder" },
  { label: 'shared',           url: '/shared',           auth: AUTH.USER, icon: "folder_shared" },
  { label: 'personal-storage', url: '/personal-storage', auth: AUTH.PERSONAL_STORAGE, icon: "upload" },
  { label: 'downloads',        url: '/downloads',        auth: AUTH.MULTI_FILE_DOWNLOAD, icon: "download" },
  { label: 'teams',            url: '/teams',            auth: AUTH.USER, icon: "groups" },
  { label: 'users',            url: '/users',            auth: AUTH.ADMIN, icon: "person" },
  { label: 'reds-search',      url: '/reds-search',      auth: AUTH.REDS, icon: "document_scanner" },
  { label: 'retention-schedules', url: '/retention-schedules', auth: AUTH.ADMIN, icon: "edit_calendar" },
  { label: 'expired-items',    url: '/expired-items',    auth: AUTH.ADMIN, icon: "edit_calendar" },
  { label: 'keywords',         url: '/keywords',         auth: AUTH.ADMIN, icon: "tag" },
  { label: 'trash',            url: '/trash',            auth: AUTH.ADMIN, icon: "delete"},
];
export default {
  computed: {
    ...mapState({
      isAdmin: (state) => state.auth.userData?.isAdmin ?? false,
      hasPersonalStorage: (state) => state.auth.userData?.personalStorageFolder ?? false,
      isRedsUser: (state) => {
        let ud = state.auth.userData;
        return (ud?.roles.includes('REDS') || ud?.isAdmin) ?? false
      },
      hasDownloads: (state) => state.auth.userData?.personalStorageFolder ?? false,
    }),
    visibleItems() {
      return ITEMS.filter((item) => {
        if (item.auth === AUTH.PERSONAL_STORAGE) {
          return this.hasPersonalStorage
        } else if (item.auth === AUTH.USER) {
          return true;
        } else if (item.auth === AUTH.ADMIN) {
          return this.isAdmin;
        } else if (item.auth === AUTH.REDS) {
          return this.isRedsUser
        } else if (item.auth === AUTH.MULTI_FILE_DOWNLOAD) {
            return true;
        }
      });
    },
  },
};
</script>

<template>
  <nav>
    <ul>
      <li v-for="item in visibleItems" :key="item.url">
        <!-- <router-link :to="item.url"><span v-if="item.icon" class="material-icons">{{item.icon}}</span> {{ $t(item.label) }}</router-link> -->
        <router-link :to="item.url">{{ $t(item.label) }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<style lang="scss" scoped>
nav {
  background: #002856;
  color: white;

  padding: 2.5rem 1rem 5rem 3.5rem;
}

li {
  margin: 0 0 2rem;
}

a:link, a:visited {
  color: white;
  text-decoration: none;
}

a:active, a:hover {
  color: white;
  text-decoration: underline;
}
</style>
