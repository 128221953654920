<script>
import { mapState } from "vuex";

export default {
  methods: {
    async logout() {
      await this.$store.dispatch("clearUserToken");
      document.location.href = '/'; // Force a "real" navigation to ensure ALL local data is flushed
    },
  },
  computed: {
    ...mapState({
      userData: (state) => state.auth.userData,
    }),
  },
};
</script>

<template>
  <div class="user-info" v-if="this.userData">
    <div class="user-avatar"></div>
    <div class="user-stack">
      <div class="user-name">{{ this.userData.name }}</div>
<!--      <div class="user-actions">-->
<!--        <a href="#" @click.prevent="logout">{{ $t("logout") }}</a>-->
<!--      </div>-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.user-info {
  display: flex;
  align-items: center;
}

.user-avatar {
  flex: 0 0 auto;
  margin-right: 0.5rem;

  width: 2.75rem;
  height: 2.75rem;
  background: #DDDDDD;
  border-radius: 100%;
}

.user-stack {
  flex: 1 0 auto;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-items: center;
}

.user-name {
  margin-bottom: 0.125rem;
  font-size: 0.875rem;
}

.user-actions {
  font-size: 0.75rem;

  a:link, a:visited {
    color: #EB5757;
    text-decoration: none;
  }

  a:active, a:hover {
    color: #EB5757;
    text-decoration: underline;
  }
}
</style>
