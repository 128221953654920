<script>
import { mapState } from "vuex";

const icons = {
  success: "done",
  error: "error",
  search: "search"
};

export default {
  data() {
    return {
      icons,
    };
  },
  computed: {
    ...mapState({
      messages: 'messages',
    }),
  },
  methods: {
    removeMessage(id) {
      this.$store.dispatch('removeMessage', id);
    }
  }
};
</script>

<template>
  <ul class="message-bar-fixed">
    <li
        v-for="message in messages"
        :key="message.id"
        :class="message.type"
        class="message-card"
        @click="removeMessage(message.id)"
    >
      <div class="icon">
        <m-icon :icon="icons[message.type]" />
      </div>
      <p>{{ message.label }}</p>
    </li>
  </ul>
</template>

<style lang="scss" scoped>
.message-bar-fixed {
  z-index: 8;
  position: fixed;
  bottom: 0.25rem;
  padding: 0;
  margin-left: 0.5rem;
}

.message-card {
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.87);
  color: white;
  font-size: 0.875rem;
  margin: 0.25rem;
  padding: 0.6rem 1rem 0.6rem 0.75rem;
  border-radius: 4px;
  cursor: pointer;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    margin-right: 1rem;
    border-radius: 100%;
    background-color: white;
    color: black;
  }

  &.success {
    .icon {
      background-color: #3CD42E;
    }
  }

  &.error {
    .icon {
      background-color: #F5627D;
    }
  }
}
</style>
