import { API_BASE } from "../util/constants";
import { showError, showSuccess } from "./notification";

export async function withLoader($store, promiseFactory, enabled = true) {
  if (!enabled) {
    return await promiseFactory();
  }

  const loadID = {};
  await $store.dispatch("beginLoad", loadID);
  try {
    return await promiseFactory();
  } finally {
    $store.dispatch("endLoad", loadID).catch(() => null);
  }
}

export function callAPI($store, url, { query, showLoader, headers = {}, ...options } = {}) {
  return withLoader($store, async () => {
    const token = $store.state.auth.token;
    if (!token) {
      showError($store, "Not logged in. Please log in and try again.");
      throw new Error('not logged in');
    }

    let fullUrl = `${API_BASE}/${url}`;
    if (query) {
      fullUrl += '?' + new URLSearchParams(query).toString();
    }
    const response = await fetch(fullUrl, {
      ...options,
      headers: {
        ...headers,
        Authorization: `Bearer ${token}`,
      },
    });



    if (response.status === 401) { // Unauthorized
      // our token has expired; show an error and present the login screen
      await $store.dispatch("clearUserToken");
      showError($store, "Login expired. Please log back in and try again.");
      throw new Error('login expired');
    }

    if (response.status === 403) { // Forbidden
      // token is valid but we are not allowed to perform this operation
      const data= await response.json();
      showError($store, data.message);
      let error = new Error(data.message);
      error.code = data.code;
      throw error;
    }

    if (response.status >= 500) {
      console.error(`API call to ${url} returned ${response.status}`, await response.text());
      showError($store, "Internal error.");
      throw new Error('internal error');
    }

    if (response.status >= 400) {
      const data = await response.json();
      console.error(`API call to ${url} returned ${response.status}`, data.message);
      if (data.code === 'DUPLICATE_ITEM') {
        showSuccess($store, data.message);
      } else {
        showError($store, data.message);
      }
      let error = new Error(data.message);
      error.code = data.code;
      throw error;
    }

    if (response.status === 204) { // No Content
      return null;
    }

    return await response.json();

  }, showLoader);
}

export const apiMixins = {
  methods: {
    // usage: this.withLoader(async () => {...});
    withLoader(promiseFactory, enabled) {
      return withLoader(this.$store, promiseFactory, enabled);
    },
    callAPI(url, options) {
      return callAPI(this.$store, url, options);
    },
  },
};
