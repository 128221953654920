import { folderMixins } from "./folder";

// We could always call the /item/folder/{id}/path API whenever we need to
// show breadcrumbs, but that would be wasteful. Often we know the path
// because the user just selected it, so cache it:

const CACHE = new Map();

export const folderPathCacheMixins = {
  mixins: [folderMixins],
  methods: {
    cacheFolderPath(item) {
      const parent = CACHE.get(item.parentId);
      if (parent && !CACHE.has(item.id)) {
        CACHE.set(item.id, [...parent, { id: item.id, name: item.name }]);
      }
    },
    cacheRepositoryBasePath(item) {
      CACHE.set(item.baseFolder.id, [{ id: item.baseFolder.id, name: item.name }]);
    },
    async getFolderPathCached(id) {
      const cached = CACHE.get(id);
      if (cached) {
        return cached;
      }
      const path = await this.getFolderPath(id, { showLoader: false });
      for (let i = 0; i < path.length; ++ i) {
        CACHE.set(path[i].id, path.slice(0, i + 1));
      }
      return path;
    },
  },
};
