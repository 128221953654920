import { apiMixins } from "./api";

const FOLDER_ENDPOINT = "item/folder";
const GROUP_REGISTER_ENDPOINT = "group_register";

export const folderMixins = {
         mixins: [apiMixins],
         methods: {
           async createFolder(parentItem, name, defaultRetention) {
             await this.callAPI(FOLDER_ENDPOINT, {
               method: "POST",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({
                 parentId: parentItem.id,
                 name,
                 defaultRetention,
               }),
             });
           },
           async createGroupRegister(parentItem, name, defaultRetention) {
             await this.callAPI(GROUP_REGISTER_ENDPOINT, {
               method: "POST",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({
                 parentId: parentItem.id,
                 name,
                 defaultRetention
               }),
             });
           },
           async createGroupRegisterFolder(parentItem, name, defaultRetention) {
             await this.callAPI(`${GROUP_REGISTER_ENDPOINT}/${parentItem.id}`, {
               method: "POST",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({
                 parentId: parentItem.id,
                 name,
                 defaultRetention,
               }),
             });
           },
           async forceCreateGroupRegisterFolder(parentItem, name, defaultRetention) {
             await this.callAPI(
               `${GROUP_REGISTER_ENDPOINT}/${parentItem.id}/force`,
               {
                 method: "POST",
                 headers: { "Content-Type": "application/json" },
                 body: JSON.stringify({ parentId: parentItem.id, name, defaultRetention }),
               }
             );
           },
           async getNextGroupRegisterPrimaryNumber(
             folderId,
             { showLoader = false } = {}
           ) {
             return await this.callAPI(
               `${GROUP_REGISTER_ENDPOINT}/${folderId}/next`,
               {
                 showLoader,
               }
             );
           },
           async getNextGroupRegisterSecondaryNumber(
             folderId,
             primaryNumber,
             { showLoader = false } = {}
           ) {
             return await this.callAPI(
               `${GROUP_REGISTER_ENDPOINT}/${folderId}/next/${primaryNumber}`,
               {
                 showLoader,
               }
             );
           },
           async getGroupRegisterPrimaryNumbers(
             folderId,
             { showLoader = false } = {}
           ) {
             return await this.callAPI(
               `${GROUP_REGISTER_ENDPOINT}/${folderId}/primary`,
               {
                 showLoader,
               }
             );
           },
           async createPaths(parentItem, paths, tags, metadata) {
             return await this.callAPI(`${FOLDER_ENDPOINT}/paths`, {
               method: "POST",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({
                 parentId: parentItem.id,
                 paths: paths,
                 tags: tags,
                 metadata: metadata,
               }),
             });
           },
           async getFolder(
             id,
             {
               page = 0,
               pageSize = 0,
               sort,
               showLoader = true,
               filter = "",
               onlyFolders = false,
               flag = "standard",
             } = {}
           ) {
             return await this.callAPI(`${FOLDER_ENDPOINT}/${id}`, {
               method: "GET",
               query: { page, pageSize, sort, filter, onlyFolders, flag },
               showLoader,
             });
           },
           async getAllItems({
             page = 0,
             pageSize = 0,
             sort,
             showLoader = true,
             filter = "",
           } = {}) {
             return await this.callAPI(`item/shared`, {
               method: "GET",
               query: { page, pageSize, sort, filter },
               showLoader,
             });
           },
           async getDownloadItems({
             page = 0,
             pageSize = 0,
             sort,
             showLoader = true,
             filter = "",
           } = {}) {
             return await this.callAPI(`item/downloads`, {
               method: "GET",
               query: { page, pageSize, sort, filter },
               showLoader,
             });
           },
           async getFolderPath(id, { showLoader = true } = {}) {
             const response = await this.callAPI(
               `${FOLDER_ENDPOINT}/${id}/path`,
               { method: "GET", showLoader }
             );
             return response.paths;
           },
           async getRecursiveFolderProperties(id, { showLoader = true } = {}) {
             const response = await this.callAPI(
               `${FOLDER_ENDPOINT}/${id}/recursive`,
               { method: "GET", showLoader }
             );
             return {
               numberOfFiles: response.properties.numberOfFiles,
               totalSize: response.properties.totalSize,
               numberOfNonExpiredFiles:
                 response.properties.numberOfNonExpiredFiles,
               numberOfSuspendedFiles:
                 response.properties.numberOfSuspendedFiles,
             };
           },
           async renameFolder(item, name) {
             await this.callAPI(`${FOLDER_ENDPOINT}/${item.id}`, {
               method: "PATCH",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({ name }),
             });
           },
           async moveFolder(item, parentItem) {
             await this.callAPI(`${FOLDER_ENDPOINT}/${item.id}`, {
               method: "PATCH",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({ parentId: parentItem.id }),
             });
           },
           async deleteFolder(item) {
             await this.callAPI(`${FOLDER_ENDPOINT}/${item.id}`, {
               method: "DELETE",
             });
           },
           async setFolderSteward(item, steward) {
             await this.callAPI(`${FOLDER_ENDPOINT}/${item.id}`, {
               method: "PATCH",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({ steward }),
             });
           },
           async updateFolder(
             item,
             { suspensionOrders, steward, owner, tags, metadata, groupRegister, defaultRetention }
           ) {
             let suspensionOrdersList = null;
             if (suspensionOrders || suspensionOrders === "") {
               suspensionOrdersList =
                 suspensionOrders.length === 0
                   ? []
                   : suspensionOrders.split(",");
             }
             await this.callAPI(`${FOLDER_ENDPOINT}/${item.id}`, {
               method: "PATCH",
               headers: { "Content-Type": "application/json" },
               body: JSON.stringify({
                 suspensionOrders: suspensionOrdersList,
                 steward,
                 owner,
                 tags,
                 metadata,
                 groupRegister,
                 defaultRetention: defaultRetention ?? ""
               }),
             });
           },
         },
       };
