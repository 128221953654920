<script>
import { mapState } from "vuex";
import MessageBar from "./MessageBar.vue";
import Navigation from "./navigation/Navigation.vue";
import Breadcrumbs from "./navigation/Breadcrumbs";
import Header from "./header/Header.vue";

export default {
  components: {
    Breadcrumbs,
    MessageBar,
    Navigation,
    Header,
  },
  computed: {
    ...mapState({
      isLoading: (state) => (state.loader.length > 0),
    }),
  },
  created() {
    this.$store.dispatch("initPage");
  },
};
</script>

<template>
  <div class="container">
    <Header class="header" />
    <Navigation class="navigation" />
    <div class="top-bar">
      <Breadcrumbs />
      <portal-target class="actions-portal" name="actions" multiple/>
    </div>
    <main>
      <m-linear-progress
          :buffer="0.2"
          :open="isLoading"
          :progress="0.2"
          class="progress"
          indeterminate
      />
      <article>
        <router-view/>
      </article>
      <MessageBar />
    </main>
  </div>
</template>

<style lang="scss" scoped>
.container {
  display: grid;
  grid-template-columns: minmax(16rem, 16rem) minmax(0, 1fr);
  grid-template-rows: minmax(auto, auto) minmax(auto, auto) minmax(0, 1fr);
  grid-template-areas:
    'header header'
    'navigation top'
    'navigation content';
  height: 100%;
}

.header {
  grid-area: header;
}

.navigation {
  grid-area: navigation;

  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.top-bar {
  grid-area: top;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E0E0E0;
  padding: 0 2rem;
}

.actions-portal {
  white-space: nowrap;
}

.actions-portal > ::v-deep button {
  margin-left: 1rem;
}

.actions-portal > ::v-deep button {
  background: transparent;
  border: 1px solid #1275CC;
  color: #1275CC;
  border-radius: 4px;
  padding: 0.75rem 1rem;
  font-weight: bold;
  cursor: pointer;
  line-height: 1.6rem;

  &:disabled {
    cursor: default;
    opacity: 0.2;
  }

  &.primary {
    background: #1275CC;
    border-color: transparent;
    color: white;
  }

  > i {
    vertical-align: -0.35rem;
    padding-right: 0.25rem;
  }
}

main {
  grid-area: content;

  overflow: auto;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.progress {
  position: sticky;
  height: 4px;
  margin-bottom: -4px;
}

article {
  padding: 0.5rem 2rem 5rem;
}
</style>

<style lang="scss">
@import './css-reset.scss';
@import './material.scss';

body {
  background: #F5F6F8;
  line-height: 1.25;
}

body, input, button {
  font: 1rem Arial, sans-serif;
}
</style>
