import Vue from "vue";
import Vuex from "vuex";
import PortalVue from "portal-vue";
import AsyncComputed from "vue-async-computed";
import Typography from "material-components-vue/dist/typography";
import Ripple from "material-components-vue/dist/ripple";
import LineRipple from "material-components-vue/dist/line-ripple";
import Chips from "material-components-vue/dist/chips";
import Button from "material-components-vue/dist/button";
import TextField from "material-components-vue/dist/text-field";
import Select from "material-components-vue/dist/select";
import Icon from "material-components-vue/dist/icon";
import IconButton from "material-components-vue/dist/icon-button";
import List from "material-components-vue/dist/list";
import Card from "material-components-vue/dist/card";
import Checkbox from "material-components-vue/dist/checkbox";
import Dialog from "material-components-vue/dist/dialog";
import NotchedOutline from "material-components-vue/dist/notched-outline";
import FloatingLabel from "material-components-vue/dist/floating-label";
import Fab from "material-components-vue/dist/fab";
import LinearProgress from "material-components-vue/dist/linear-progress";
import Elevation from "material-components-vue/dist/elevation";
import Switch from "material-components-vue/dist/switch";
import App from "./components/App.vue";
import router from "./router.js";
import makeStore from "./store.js";
import i18n from "./i18n.js";

Vue.use(Vuex);
Vue.use(PortalVue);
Vue.use(AsyncComputed);

Vue.use(Typography);
Vue.use(Ripple);
Vue.use(LineRipple);
Vue.use(Chips);
Vue.use(Button);
Vue.use(Select);
Vue.use(TextField);
Vue.use(Icon);
Vue.use(List);
Vue.use(Card);
Vue.use(Dialog);
Vue.use(NotchedOutline);
Vue.use(FloatingLabel);
Vue.use(Fab);
Vue.use(IconButton);
Vue.use(LinearProgress);
Vue.use(Elevation);
Vue.use(Switch);
Vue.use(Checkbox);

Vue.config.productionTip = false;
new Vue({
  router,
  store: makeStore(),
  i18n,
  render: h => h(App)
}).$mount("#app");
