<script>
import { notificationMixins } from "../../mixins/notification";
import {folderPathCacheMixins} from "@/mixins/folderPathCache";

export default {
  mixins: [notificationMixins, folderPathCacheMixins],
  data() {
    return {
      searchQuery: '',
    };
  },
  methods: {
    search() {
      if (this.searchQuery) {
        if (this.searchQuery.length < 3) {
          this.showError("must search for at least three characters");
          return;
        }
        this.$router.push({name: 'search', query: {q: this.searchQuery, parentId: this.parentId }})
            //.then(() => this.searchQuery = '')
            // VueJS routing is ridiculous
            // https://github.com/vuejs/vue-router/issues/2430
            .catch(error => {
              if (error.name !== "NavigationDuplicated") {
                throw error;
              }
            });
      }
    },
  },
  computed: {
    parentId() {
      return this.$route.params.id ?? 0
    }
  }
};
</script>

<template>
  <form class="search" @submit.prevent="search">
    <input v-if="parentId" type="text" v-model="searchQuery" :placeholder="$t('search-placeholder-folder')" />
    <input v-else type="text" v-model="searchQuery" :placeholder="$t('search-placeholder')" />
  </form>
</template>

<style lang="scss" scoped>
.search {
  display: flex;
  align-items: center;

  input[type=text] {
    flex: 1 1 auto;

    background: #F9F9F9;
    border: 1px solid #E5E5E5;
    border-radius: 8px;
    color: black;
    padding: 0.5rem 1rem;

    &::placeholder {
      color: #767676;
    }
  }
}
</style>
