import Vue from "vue";
import Router from "vue-router";
import store from "./store"
import {callAPI} from "./mixins/api"
import {redirectToLogin} from "./oauth"

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "repositories",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/pages/repositories/Page.vue"
        ),
    },
    {
      path: "/all",
      name: "all",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/pages/all_items/Page.vue"
        ),
    },
    {
      path: "/shared",
      name: "shared",
      component: () =>
        import(
          /* webpackChunkName: "home" */ "./components/pages/shared/Page.vue"
        ),
    },
    {
      path: "/users",
      name: "users",
      component: () =>
        import(
          /* webpackChunkName: "management" */ "./components/pages/users/Page.vue"
        ),
    },
    {
      path: "/teams",
      name: "teams",
      component: () =>
        import(
          /* webpackChunkName: "management" */ "./components/pages/teams/Page.vue"
        ),
    },
    {
      path: "/personal-storage",
      name: "personal-storage",
      beforeEnter: async (to, from, next) => {
        await store().dispatch("setBreadcrumbs", []);
        next({
          path:
            "/browse/" +
            store().state.auth.userData.personalStorageFolder +
            "/personal-storage",
        });
      },
    },
    {
      path: "/reds-search",
      name: "reds-search",
      component: () =>
        import(
          /* webpackChunkName: "reds" */ "./components/pages/reds-search/Page"
        ),
    },
    {
      path: "/reds-results",
      name: "reds-results",
      component: () =>
        import(
          /* webpackChunkName: "reds" */ "./components/pages/reds-results/Page"
        ),
    },
    {
      path: "/view/:id",
      name: "view-item",
      beforeEnter: async (to) => {
        await store().dispatch("initPage");
        let res = await callAPI(store(), "item/view/" + to.params.id);
        window.location = res.url;
      },
    },
    {
      path: "/repository/by-name",
      name: "find-repository-by-name",
      beforeEnter: async (to, from, next) => {
        await store().dispatch("initPage");
        let res = await callAPI(store(), "item/repository/by-name/", {
          query: { name: to.query.name },
        });
        next({ path: "/browse/" + res.id });
      },
    },
    {
      path: "/browse/:id/upload",
      name: "upload",
      component: () =>
        import(
          /* webpackChunkName: "browser" */ "./components/pages/upload/Page.vue"
        ),
    },
    {
      path: "/browse/:id/:flag?",
      name: "browse",
      component: () =>
        import(
          /* webpackChunkName: "browser" */ "./components/pages/browser/Page.vue"
        ),
    },
    {
      path: "/search",
      name: "search",
      component: () =>
        import(
          /* webpackChunkName: "browser" */ "./components/pages/search/Page.vue"
        ),
    },
    {
      path: "/oauth/redirect*",
      name: "oauth-redirect",
      props: true,
      component: () => import("./components/OAuth.vue"),
    },
    {
      path: "/properties/:id",
      name: "properties",
      component: () =>
        import(
          /* webpackChunkName: "management" */ "./components/pages/properties/Page.vue"
        ),
    },
    {
      path: "/folder-properties",
      name: "folder-properties",
      component: () =>
        import(
          /* webpackChunkName: "management" */ "./components/pages/folder_properties/Page.vue"
        ),
    },
    {
      path: "/apdm/v4.0/by-id/item/:id/",
      name: "apdm-handler-item-id-1",
      component: () => undefined,
    },
    {
      path: "/v4.0/by-id/item/:id/",
      name: "apdm-handler-item-id-2",
      component: () => undefined,
    },
    {
      path: "/apdm/v4.0/by-ver/item/:id/:version",
      name: "apdm-handler-item-id-version-1",
      component: () => undefined,
    },
    {
      path: "/v4.0/by-ver/item/:id/:version",
      name: "apdm-handler-item-id-version-2",
      component: () => undefined,
    },
    {
      path: "/apdm/v4.0/by-id/item/:id/:label",
      name: "apdm-handler-item-id-label-1",
      component: () => undefined,
    },
    {
      path: "/v4.0/by-id/item/:id/:label",
      name: "apdm-handler-item-id-label-2",
      component: () => undefined,
    },
    {
      path: "/apdm/v4.0/by-id/:type/:id",
      name: "apdm-handler-type-id-1",
      component: () => undefined,
    },
    {
      path: "/v4.0/by-id/:type/:id",
      name: "apdm-handler-type-id-2",
      component: () => undefined,
    },
    {
      path: "/downloads",
      name: "downloads",
      component: () =>
        import(
          /* webpackChunkName: "browser" */ "./components/pages/downloads/Page.vue"
        ),
    },
    {
      path: "/download/:id",
      name: "download-file",
      component: () =>
        import(
          /* webpackChunkName: "browser" */ "./components/pages/download/Page.vue"
        ),
    },
    {
      path: "/trash",
      name: "trash",
      component: () =>
        import(
          /* webpackChunkName: "browser" */ "./components/pages/trash/Page.vue"
        ),
    },
    {
      path: "/retention-schedules",
      name: "retention-schedules",
      component: () =>
        import(
          /* webpackChunkName: "browser" */ "./components/pages/retention/Page.vue"
        ),
    },
    {
      path: "/keywords",
      name: "keywords",
      component: () =>
        import(
          /* webpackChunkName: "browser" */ "./components/pages/keywords/Page.vue"
        ),
    },
    {
      path: "/expired-items/:folderPath?",
      name: "expired-items",
      component: () =>
        import(
          /* webpackChunkName: "browser" */ "./components/pages/expired/Page.vue"
        ),
    },
  ],
});

async function handleApdmUrl(apdmFunc) {
  let response = await apdmFunc();
  return response.path;
}

router.beforeEach(async (to, from, next) => {
  // Slight hack to handle old OAuth & UAA configuration, as the router doesn't properly handle
  // a route with a '#' in it if we're in history mode.
  if (to.fullPath.startsWith("/#/oauth/redirect")) {
    next(to.fullPath.substr(2));
    return;
  }

  await store().dispatch("initPage");
  if (!to.path.startsWith("/oauth/redirect") && !store().state.auth.token) {
    redirectToLogin({silent: false, originalRoute: to.fullPath})
  } else {
    let path = null;
    switch (to.name) {
      case "apdm-handler-item-id-1":
      case "apdm-handler-item-id-2":
        path = await handleApdmUrl(() => callAPI(store(), `apdm/v4.0/by-id/item/${to.params.id}/`, { method: "GET" }))
        break;
      case "apdm-handler-item-id-version-1":
      case "apdm-handler-item-id-version-2":
        path = await handleApdmUrl(() => callAPI(store(), `apdm/v4.0/by-ver/item/${to.params.id}/${to.params.version}`, { method: "GET" }))
        break;
      case "apdm-handler-item-id-label-1":
      case "apdm-handler-item-id-label-2":
        path = await handleApdmUrl(() => callAPI(store(), `apdm/v4.0/by-id/item/${to.params.id}/${to.params.label}`, { method: "GET" }))
        break;
      case "apdm-handler-type-id-1":
      case "apdm-handler-type-id-2":
        path = await handleApdmUrl(() => callAPI(store(), `apdm/v4.0/by-id/${to.params.type}/${to.params.id}`, { method: "GET" }))
        break;
    }

    if (path) {
      if (path.startsWith("https://")) {
        window.location = path;
      } else {
        next(path);
      }
    } else {
      next();
    }
  }
});

export default router;
