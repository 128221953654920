import store from "./store";

const iframeId = "silent-refresh-iframe-93927c75-dcaf-445d-b4ce-12282747564c";

export function redirectToLogin({silent, originalRoute}) {
    localStorage.setItem("originalRoute", originalRoute);
    const redirectPath = window.parent.origin + "/#/oauth/redirect"
    let oauthUrl = store().state.info.oauthUrl + "/oauth/authorize?response_type=id_token&client_id=4ced930c-018a-6110-c9d3-b76a5609b9c2&scope=openid&redirect_uri=" + encodeURIComponent(redirectPath);
    if (silent) {
        if (window.document.getElementById(iframeId)) {
            window.document.getElementById(iframeId).remove();
        }
        let iframe = window.document.createElement("iframe");
        iframe.id = iframeId;
        iframe.setAttribute("src", oauthUrl + "&prompt=none")
        window.document.body.appendChild(iframe);
    } else {
        if (window.parent && window.parent !== window) {
            // Inside silent-refresh iFrame
            window.parent.location = oauthUrl;
        } else {
            // Called from router
            window.location = oauthUrl;
        }
    }
}