<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      crumbs: 'crumbs',
    }),
    displayed() {
      if (this.crumbs.length <= 4) {
        return this.crumbs;
      }
      return [...this.crumbs.slice(0, 2), { label: '\u2026' }, ...this.crumbs.slice(-2)];
    },
  },
};
</script>

<template>
  <nav>
    <ol>
      <li v-for="(crumb, index) in displayed" :key="index">
        <span v-if="!crumb.to || index === (displayed.length - 1)">{{ crumb.label }}</span>
        <router-link :to="crumb.to" v-else>{{ crumb.label }}</router-link>
      </li>
    </ol>
  </nav>
</template>

<style lang="scss" scoped>
nav {
  padding: 0.5rem 0;
  font-size: 1rem;
  line-height: 1.5rem;
  min-height: 4.5rem;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

li {
  display: inline-block;
  white-space: nowrap;
  color: #767676;

  &:last-child {
    color: black;
  }

  &:not(:last-child)::after {
    content: "\203A";
    display: inline-block;
    color: #767676;
    padding: 0 0.75rem;
  }
}

a:active, a:hover {
  text-decoration: underline;
}
</style>
