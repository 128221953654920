<script>
import UserInfo from "./UserInfo.vue";
import Search from "./Search.vue";
import { mapState } from "vuex";

export default {
  components: {
    UserInfo,
    Search,
  },
  computed: {
    ...mapState({
      isLoggedIn: (state) => Boolean(state.auth.userData),
      env: (state) => state.info.environment,
    }),
  },
};
</script>

<template>
  <header>
    <h1>{{ $t('app-title', {environment: this.env}) }}</h1>
    <div class="actions" v-if="this.isLoggedIn">
      <Search class="search" />
      <UserInfo class="user-info" />
    </div>
  </header>
</template>

<style lang="scss" scoped>
header {
  background: white;
  color: black;
  padding: 0 2.2rem;
  display: flex;
  align-items: center;

  h1 {
    flex: 0 0 auto;
    margin-right: 1rem;

    font: bold 1.5rem Arial, sans-serif;
    padding: 1.25rem 0;
  }

  .actions {
    flex: 1 1 auto;

    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .search {
    flex: 0 1 20rem;
  }

  .user-info {
    flex: 0 0 auto;
    margin-left: 2rem;
  }
}
</style>
