function showMessage($store, type, message, { expiryTime = 5000 } = {}) {
  return $store.dispatch('addMessage', { type, label: message, expiryTime });
}

export function showError($store, message, options) {
  return showMessage($store, 'error', message, options);
}

export function showSuccess($store, message, options) {
  return showMessage($store, 'success', message, options);
}

export const notificationMixins = {
  methods: {
    showError(message, options) {
      return showError(this.$store, message, options);
    },
    showSuccess(message, options) {
      return showSuccess(this.$store, message, options);
    },
  },
};
